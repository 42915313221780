
.corner {
  position: sticky;
  /* display: contents; */
  left: 0;
  top: 0;
  z-index: 500;
  background-color: #F5F5F5;
  min-width: 80px;
  width: 80px;
  height: 60px;
  border-right: solid 1px rgba(0, 0, 0, 0.125);
  border-bottom: solid 2px rgba(0, 0, 0, 0.125);
  border-top: solid 2px rgba(0, 0, 0, 0.125);
  justify-content: center;
  align-items: center;
}


.day-selector {
  width: 100%;
  padding: 10px;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.day-selector:hover {
  cursor: pointer;
}

.day-selector-item {
  text-align: center;
  width: 100%;
  position: relative;
  display: block;
}


.day-text {
  width: 100%;
  text-transform: uppercase;
  font-weight: 600;
  color: white;
}

.day-number {
  width: 100%;
  color: white;

}

.day-number-selected {
}

.punkt-selected {
  height: 25px;
  width: 25px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
}

.large-selected {
  font-weight: 900;
  text-decoration: underline;
}


